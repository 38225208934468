/* eslint-disable no-magic-numbers */

import { intializeHeadroom } from "../../../bladejs/initialize-headroom";

const toggleVisible = (element) => {
	element?.classList.toggle("tw-hidden");
};

document.addEventListener("DOMContentLoaded", () => {
	// Handle Homepage Select options clicks
	document.querySelectorAll(".dropdown-menu li")?.forEach((item) => {
		item.addEventListener("click", function () {
			const getValue = this.textContent.trim();
			this.closest(".dropdown").querySelector(".dropdownSelect").textContent =
				getValue;

			// Update the discover button link
			const catSlug = this.getAttribute("value");
			const btnDiscover = document.querySelector(".btn_discover");
			btnDiscover.classList.remove("disabled");
			btnDiscover.setAttribute("href", catSlug);
		});
	});

	// Used sitewide for hiding/displaying header on scroll
	intializeHeadroom();

	document
		.querySelectorAll(".singleCompanyDetail-toggleBenefits")
		?.forEach((toggle) => {
			toggle.addEventListener("click", function () {
				this.style.display = "none";
				document
					.querySelectorAll(".singleCompanyDetail-hideBenefitsContainer")
					?.forEach((container) => {
						container.style.display = "block";
					});
			});
		});

	// Menu toggles
	const desktopMenu = document.getElementById("desktopMenu");
	const mobileMenu = document.getElementById("mobileMenu");
	const expandDesktopMenu = document.getElementById("expandDesktopMenu");
	const expandMobileMenu = document.getElementById("expandMobileMenu");

	const toggleMobileMenu = () => {
		const hamburgerIcon = expandMobileMenu.querySelector("figure");
		const closeIcon = expandMobileMenu.querySelector("p");

		toggleVisible(hamburgerIcon);
		toggleVisible(closeIcon);
		toggleVisible(mobileMenu);

		document.documentElement.classList.toggle(
			"tw-overflow-hidden",
			!mobileMenu?.classList.contains("tw-hidden"),
		);
	};

	window.addEventListener("resize", () => {
		if (!mobileMenu?.classList.contains("tw-hidden")) toggleMobileMenu();
	});

	expandDesktopMenu?.addEventListener("click", () => {
		toggleVisible(desktopMenu);
	});

	expandMobileMenu?.addEventListener("click", () => {
		toggleMobileMenu();
	});
});
