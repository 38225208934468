import Headroom from "headroom.js";

// Used sitewide for hiding/displaying header on scroll
export const intializeHeadroom = () => {
	let headroom;
	// https://wicky.nillia.ms/headroom.js/#:~:text=an%20interactive%20demo.-,Browser%20support,-Headroom.js%20is
	if (Headroom.cutsTheMustard) {
		headroom = new Headroom(document.querySelector("#header"), {
			tolerance: {
				up: 8,
				down: 5,
			},
			offset: 120,
		});

		headroom.init();
	}

	return headroom;
};
